//
// Theme style
//

// Initialize
@import '~react-toastify/dist/ReactToastify.min.css';
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pointer-cust {
  cursor: pointer;
}

.cust_disabled {
  pointer-events: none;
  opacity: 0.5;
}

.assets_head,
.item_head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: #fff;

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
    min-width: 170px;
    padding-bottom: 17px;
  }

  .link {
    border: 2px solid #ddd;
    border-radius: 30px;
    padding: 6px 20px;
    display: inline-block;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    float: left;
    transition: all 0.2s ease-out;
    background-color: #fff;

    span {
      color: #2c6dac;
    }

    &:hover {
      background-color: #2c6dac;
      color: #fff;
      border-color: #2c6dac;

      span {
        color: #fff;
      }
    }
  }

  .rightauto {
    margin-right: auto;
  }
}


.cust_link_btn {
  border: 2px solid #ddd;
  border-radius: 30px;
  padding: 6px 20px;
  display: inline-block;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  float: left;
  transition: all 0.2s ease-out;
  background-color: #fff;

  span {
    color: #2c6dac;
  }

  &:hover {
    background-color: #2c6dac;
    color: #fff;
    border-color: #2c6dac;

    span {
      color: #fff;
    }
  }
}

.cust-login-btn {
  border: 2px solid #ddd !important;
  border-radius: 30px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: #2c6dac;
    color: #fff;
    border-color: #2c6dac !important;
  }
}

#kt_app_sidebar_menu_wrapper {
  height: 100%;
}

.strikeThrough {
  text-decoration: line-through;
  color: #599f59 !important;
}

.nodatafound {
  width: 100% !important;
  margin: 20px 0;
  width: 31.33%;
  margin-bottom: 1%;
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 15px;
  padding-right: 70px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

@media only screen and (max-width: 600px) {
  .assets_head.marketing {
    display: block !important;
  }
}

.assets_head.marketing {
  margin-bottom: 0px;
  margin-top: 15px;
}

.assets_tabs {
  .nav-tabs {
    border: none;

    .nav-item {
      margin-right: 20px;

      .nav-link {
        font-size: 16px;
        font-weight: 600;
        padding: 10px 0;
        color: #999;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
          color: #000;
          border-bottom-color: #2c6dac;
        }
      }
    }
  }

  .tabboxmain {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    margin: 20px 0;

    .subbox {
      margin-bottom: 1%;
      border: 2px solid #eee;
      border-radius: 10px;
      padding: 15px;

      position: relative;

      h4 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
        color: #000;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #999;
      }


      .actiondiv {
        position: absolute;
        right: 15px;
        top: 20px;

        a {
          color: #2c6dac;
          font-size: 20px;
          margin: 5px;
        }
      }
    }

    .subbox:hover {
      background-color: #f9fdff;
      border-color: #bae5ff;
      cursor: pointer;
    }
  }
}

.assets_head .sel_filter {
  margin-left: 20px;
  border: 2px solid #ddd;
  border-radius: 30px;
  padding: 6px 20px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  float: left;
  cursor: pointer;
}

.dull {
  color: #999;
  padding-left: 1.6rem;
}

.custom_modal {
  .form-group {
    margin-bottom: 20px;
  }

  .labels {
    display: block;
    margin-bottom: 5px;
    color: #000;
    font-weight: 600;
    font-size: 14px;
  }

  .form-control {
    background-color: #f9f9f9;
    border: none;
    border-radius: 5px;
    appearance: auto;
  }

  .modal-footer {
    justify-content: center;
  }

  .modal-footer .btn-secondary {
    background-color: #f9f9f9;
  }

  .modal-footer .btn-secondary:hover {
    background-color: #f9f9f9 !important;
  }

  // .modal-footer .btn-primary {
  //     // background-color: #2C6DAC !important;
  // }

  .modal-footer .btn-active {
    background-color: #0095e8 !important;
    color: #fff;
  }

  // .modal-footer .btn-primary:hover {
  //     background-color: #2C6DAC !important;
  // }

  .cust_textArea {
    height: 100px;
    resize: none;
  }
}

.sele_dropdown {
  float: left;

  .dropdown-menu {
    padding: 5px 10px;
    min-width: 210px;

    .form-check {
      margin: 10px 0;

      .form-check-label {
        color: #000;
        font-weight: 500;
      }
    }

    .form-check-input:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }
}

.btn-light-primary {
  border: 1px solid !important;
}

.cust_tagList {
  display: inline-block;
  margin: 0px 0px 5px 0px;

  button {
    border: none;
    background: none;
    font-size: 16px;
    border-radius: 5px;
  }

  .tag {
    background-color: #f4f4f4;
    padding: 4px 8px;
    margin-right: 5px;
  }
}

.activity_song_drop {
  .sele_dropdown {
    display: block;
    float: none;

    .sel_count {
      min-width: 28px;
      display: inline-block;
    }
  }

  .dropdown-toggle {
    background-color: #f9f9f9;
    border: none !important;
    border-radius: 5px;
    margin-left: 0;
    width: 100%;
    text-align: left;
    color: #666;
    font-size: 14px;
    padding: 8px 14px !important;
  }
}

.pg {
  .pg-left {
    font-size: 14px;
    color: #404254;
  }

  .pg-right {
    font-size: 14px;
    color: #0f0f0f;
    font-weight: 500;
  }

  .progress-bar {
    background-color: #404254;
  }
}

.border-radius-5 {
  border-radius: 5px;
}

.color-box {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 0px;
}

.show-more {
  cursor: pointer;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.custom_according,
.item-body {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 0 15px;
  margin-bottom: 15px;

  .sub_according {
    background-color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 15px;
    padding: 0 15px;
    margin: 10px 0;
  }

  .accordion-heading {
    padding-left: 1rem;
    border-bottom: 1px solid #fafafa;
  }
}

.app-sidebar-header {
  .btn-custom {
    border: 1px solid #313131 !important;
    background-color: #101010 !important;
    padding: 14px 16px !important;
  }

  .menu {
    background-color: #131313;
    border: 1px dashed #313131;

    .menu-item .menu-link:hover {
      background-color: #1e1f27 !important;
    }
  }
}

.my-modlist_activity_modal {
  .actionbtn {
    color: #2c6dac;
    cursor: pointer;
    margin: 0 5px;
  }
}

.cust-metronic {
  position: relative;
}

.cust-metronic .show {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 0 !important;
}

.head_title {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 5px;

  h4 {
    display: inline-block;
    color: #999;
    text-transform: uppercase;
    margin: 8px 0;
  }

  .btn {
    color: #ccc;
    float: right;
    border: 1px solid #999 !important;
    padding: 6px 10px !important;
  }
}

.error-form {
  color: #e12c2d;
}

.cust_pagination {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 20px 0;

  button {
    border: 1px solid #e5e5e5;
    padding: 4px 12px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.4;
  }

  .page-numbers {
    list-style: none;
    padding: 0 15px;
    margin: 0;

    li {
      float: left;
      margin: 0 5px;
      border: 1px solid #e5e5e5;
      padding: 4px 12px;
      font-weight: 500;
      color: #4b5675;
      border-radius: 6px;
      cursor: pointer;
    }

    .active {
      color: #fff;
      background-color: #089ef7;
      border: 1px solid  #089ef7;
      ;
    }
  }
}

.heading-checkbox {
  float: left;
  position: relative;
}

.table .hide {
  display: none;
}

.delete-confirmation-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1099;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  border-radius: 6px;
  padding-top: 20%;

  .dialog-box {
    background-color: #fff;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;

    .dialog-text {
      font-size: 18px;
      margin-bottom: 15px;
      max-width: 550px;
    }

    .btn-save {
      background-color: #e12c2d;
      color: #fff;
      border: 2px solid #e12c2d;
      font-weight: 600;
      border-radius: 3px;
      padding: 4px 16px;
      margin: 5px;
    }

    .btn-cancel {
      border: 2px solid #999;
      font-weight: 600;
      border-radius: 3px;
      padding: 4px 16px;
      margin: 5px;
    }
  }
}

.cust_loader {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  text-align: center;
  padding-top: 20%;

  color: var(--bs-primary);

  img {
    max-width: 80px;
  }
}

.cust_editicon {
  position: absolute;
  right: 20px;
  top: 0;
  padding: 5px 10px;
  cursor: pointer;
}

.cust_lockicon {
  position: absolute;
  right: 40px;
  top: 0;
  padding: 5px 10px;
  cursor: pointer;
}

.cust_deleteicon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
  cursor: pointer;
}

.commentbox {
  display: inline-block;
  width: 100%;
  padding: 10px;
  height: calc(100vh - 130px);
  overflow: auto;

  .comment {
    display: flex;
    align-items: start;
    padding: 15px 0;
    width: 100%;
    border-bottom: 1px solid #eee;
    position: relative;

    .imgdiv {
      width: 50px;
      padding-right: 10px;

      .imgs {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background-color: #ccc;
        text-align: center;
      }
    }

    .datadiv {
      margin-top: 5px;

      .namesdiv {
        padding-right: 70px;

        .names {
          color: #000;
          font-weight: 600;
          font-size: 16px;
        }

        .dayago {
          color: #999;
        }
      }

      .desq {
        color: #666;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.4;
      }
    }

    .editdiv {
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }
}

.commentboxform {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 10px;

  .form-control {
    height: auto;
    padding: 20px 10px;
    border-radius: 5px;
    padding-right: 80px;
    font-size: 14px;
    font-weight: normal;
  }

  .btn-submit {
    position: absolute;
    right: 8px;
    top: 18px;
    border-radius: 15px;
    background-color: #2c6dac;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.1;
    padding: 7px 20px !important;
  }
}

.hover-user {
  border: 2px solid #bae5ff;
  border-radius: 10px;
  background-color: #f9fdff;
}

.color-red {
  color: #e12c2d;
}

.hidden {
  visibility: hidden;
}

.align-right-auto {
  margin-left: auto;
}

.user-border {
  border: 2px solid #e8eaef;
  border-radius: 10px;
}

.user-manager-text {
  font-weight: 400;
  color: #7e8299;
}

.fw-400 {
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  #kt_app_header {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .my-modlist_activity_modal {
    max-width: 1000px;
  }
}

.form-check-heading {
  font-weight: 600;
  font-size: 0.95rem;
}

.form-switch .form-check-input {
  width: 4.25rem;
}

.dropdown-menu-center {
  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -o-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}

.has-tag {
  position: absolute;
  top: 12px;
  left: 46px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.uppercase {
  text-transform: uppercase;
}

.rotated {
  transform: rotate(180deg);
  /* Equal to rotateZ(45deg) */
}

.border-right {
  border-right: 1px solid #e8eaef;
}

.m-l-auto,.ml-auto {
  margin-left: auto
}

.mr-auto{
  margin-right:auto
}

.btn.rounded{
  background-color: #fff !important;
  border: 1px solid #e8eaef !important;
  border-radius: 20px !important;
  color: #7e8299 !important;
} 
.btn.rounded:hover{
  color: #000 !important;
}
.btn.ouline-primary{
  color: #009ef7 !important;;
  border-color:#009ef7 !important;
}